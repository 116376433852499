<template>
  <sm-page-loader v-if="list.isLoading" />
  <div v-else class="editable-list">
    <sm-breadcrumbs class="editable-list__breadcrumbs" :items="breadcrumbs" />

    <sm-datatable
      class="editable-list__datatable"
      :caption="tableCaption"
      :headers="tableHeaders"
      :items="tableList"
    />
  </div>
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('editableList');
// components
import SmPageLoader from '@/components/common/SmPageLoader.vue';
import SmBreadcrumbs from '@/components/common/breadcrumbs/SmBreadcrumbs.vue';
import SmDatatable from '@/components/common/SmDatatable.vue';

export default {
  name: 'OneCContactsForTelemarketingTask',

  components: {
    SmPageLoader,
    SmBreadcrumbs,
    SmDatatable,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'OneCContacts',
      tableCaption: 'Контакты',
      tableHeaders: [
        { text: 'Логин', alias: 'login', order: 'login' },
        { text: 'Пароль', alias: 'password', order: 'password' },
        { text: 'ФИО', alias: 'fio', order: 'fio' },
        { text: 'Создан', alias: 'created', order: 'created' },
        { text: 'Почта', alias: 'mail', order: 'mail' },
        { text: 'Должность', alias: 'position', order: 'position' },
        { text: 'Телефон', alias: 'phone', order: 'phone' },
        {
          text: 'Верификационный телефон',
          alias: 'verificatedPhone',
          order: 'verificatedPhone',
        },
        {
          text: 'Верифицирован?',
          alias: 'isPhoneVerificated',
          order: 'isPhoneVerificated',
        },
        { text: 'Передана в пулл?', alias: 'isPool', order: 'isPool' },
      ],
    };
  },

  computed: {
    ...mapState(['item', 'list']),

    tableList() {
      return this.list.data?.items.map((item) => ({
        ...item,
        isPhoneVerificated: item.isPhoneVerificated ? 'Да' : 'Нет',
        isPool: item.isPool ? 'Да' : 'Нет',
      }));
    },

    id() {
      return +this.$route.params.id;
    },

    inn() {
      return +this.$route.params.inn;
    },

    breadcrumbs() {
      return [
        {
          text: 'Телемаркетинг',
          route: { name: 'TelemarketingMain' },
        },
        {
          text: 'Задания по телемаркетингу для текущего пользователя',
          route: { name: 'TelemarketingTasksForCurrentUser' },
        },
        {
          text: `Редактирование задания по телемаркетингу для текущего пользователя №${this.id}`,
          route: {
            name: 'TelemarketingTaskForCurrentUserEdit',
            params: { id: this.id },
          },
        },
        {
          text: `Контакты для ИНН ${this.inn}`,
        },
      ];
    },
  },

  created() {
    this.getItem({ name: 'TelemarketingTasks', id: this.id });

    this.getList({
      name: 'Accounts',
      params: {
        filters: [{ column: 'inn', operation: 'Contain', value: this.inn }],
        skip: 0,
        count: 150,
      },
    });
  },

  methods: {
    ...mapActions(['getItem', 'getList']),
  },
};
</script>

<style lang="scss">
.editable-list__breadcrumbs {
  margin-bottom: 15px;
}
</style>
